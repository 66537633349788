import {
  accessGroupMenuItemsWatcher,
  addAccessGroupWatcher,
  disableAccessGroupWatcher,
  editAccessGroupWatcher,
  fetchAccessGroupMasterWatcher,
  fetchAccessGroupsAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/access-group-master/redux/saga';
import {
  addAdminUserWatcher,
  disableAdminUserWatcher,
  editAdminUserWatcher,
  fetchAccessGroupDropDownWatcher,
  fetchAdminAddUsersAutoSuggestDataWatcher,
  fetchAdminUsersAutoSuggestDataWatcher,
  fetchAdminUsersDataWatcher,
  fetchDivisionDropdownWatcher,
  fetchStateDropdownWatcher,
} from "@app/screens/access-management/pages/user-management/redux/saga";
import {
  applyFilterWatcher,
  applySetDefaultFilterWatcher,
} from '@app/components/right-sidebar/redux/filterHandlerSaga';
import {
  directoryLoadingHandlerWatcher,
  directoryNavActionHandlerWatcher,
  directoryNavSearchQueryWatcher,
  directoryNavToggleWatcher,
  directoryUpdateCurrentPageWatcher,
  updateDirectoryNavWatcher,
} from "@app/screens/directory-configuration/redux";
import {
  fetchPlanAndMeetStatusListWatcher,
  updateMTPStatusWatcher,
  BulkGSPUpdateWatcher
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import { all, call, spawn } from 'redux-saga/effects';
import {
  fetchCannotAccessModalUsersWatcher,
  fetchNotificationsWatcher,
  fetchUserAppSettingsWatcher,
  fetchUserInfoWatcher,
  markNotificationReadWatcher,
} from '@app/store/sagas/appSaga';
import {
  fetchDivisionsWatcher,
  fetchPartyGroupsWatcher,
  updateTabsWatcher,
} from "@app/screens/directory-configuration/pages/tab-management/redux";
import { fetchFromHQWatcher, fetchSLMWatcher, fetchSalesTransferWatcher, fetchToHQWatcher, fetchYearlyQuartersWatcher } from '@app/screens/incentive-configuration/redux/saga';
import {
  fetchFilterOptionsWatcher,
  fetchHqsWatcher,
  fetchMTPRecommendationsWatcher,
  filterBasedDownloadExcelWatcher,
  fetchPreferredFiltersWatcher,
  fetchMTPUnlockReasonsWatcher,
  enableDCRWatcher,
  fetchPlanAndMeetHierarchyWatcher,
  HierarchyBasedDownloadExcelWatcher,
  webAccessUnlockWatcher,
  getWebAccessStatusWatcher
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/saga';
import {
  fetchHoUserCategoryWatcher,
  fetchHoUserDepartmentWatcher,
  fetchHoUserDivisionWatcher,
  fetchHoUserListWatcher,
  fetchHoUserRegionWatcher,
  fetchHoUserStateWatcher,
} from "@app/screens/directory-configuration/pages/ho-user/redux";
import {
  fetchMappingFieldsWatcher,
  fetchTabListWatcher,
  updateColumnsWatcher,
} from "@app/screens/directory-configuration/pages/column-management/redux";
import {
  getSocialMediaCategoryWatcher,
  updateSocialMediaListWatcher,
} from "@app/screens/directory-configuration/pages/social media/redux";
import { incentiveNavActionHandlerWatcher, incentiveNavDivisionListWatcher, incentiveNavSearchQueryWatcher, incentiveNavStateLisWatcher, incentiveNavToggleWatcher, incentiveUpdateCurrentPageWatcher } from '@app/screens/incentive-configuration/redux';
import { fetchDepartmentListWatcher } from "@app/screens/directory-configuration/pages/department-management/redux";
import { fetchHoContactCategoryWatcher } from "@app/screens/directory-configuration/pages/ho-contact-category/redux";
import { fetchRoutesDataWatcher } from '@app/router/redux/routeHandlerSaga';
import { fetchSocialMediaCategoryListWatcher } from "@app/screens/directory-configuration/pages/social-media-category/redux/saga";
import { fetchUnlockRequestWatcher } from '@app/screens/incentive-configuration/pages/unlock-request/redux';
import { getUnlockRequestTypesWatcher } from '@app/screens/incentive-configuration/pages/unlock-request/redux/saga';
import {
  moduleSpecificUtilitiesLoadingHandlerWatcher, 
  moduleSpecificUtilitiesNavActionHandlerWatcher, 
  moduleSpecificUtilitiesNavSearchQueryWatcher, 
  moduleSpecificUtilitiesNavToggleWatcher, 
  moduleSpecificUtilitiesUpdateCurrentPageWatcher, 
  updateModuleSpecificUtilitiesNavWatcher 
} from '@app/screens/module-specific-utilities/redux';
import {modifyDCRRequestWatcher} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/saga';
import {
  fetchDcrUnlockRequestListWatcher,
  fetchDCRUnlockReasonsWatcher,
  approveDCRUnlockRequestWatcher,
  fetchDCRHqsWatcher,
  dcrDownloadExcelWatcher,
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';
import { fetchPartiesBySpidWatcher, 
  fetchPartyDetailBySpidWatcher, 
  fetchCloningPeriodWatcher, 
  transferPositionUpdateWatcher, 
  fetchPositionCloningListWatcher, 
  clonePositionUpdateWatcher, 
  fetchPositionTransferListWatcher,
  validatePartyTransferWatcher
} from '@app/screens/module-specific-utilities/pages/position-management/redux';
import {fetchApprovalWorkflowListWatcher, fetchMasterConfigDataWatcher, createApprovalResponseWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux';
import {updateMinApproverConfigWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux/saga';
import { fetchMetricCategoryListWatcher } from '@app/screens/module-specific-utilities/pages/metric-category/redux';
import { fetchMetricCategoryDropdownWatcher } from '@app/screens/module-specific-utilities/pages/metric-category/redux/saga';
import { fetchDownloadExcelURLWatcher, fetchPartyMastersWatcher } from '@app/screens/module-specific-utilities/pages/digital-twin-party-master/redux';
import { fetchMetricPropertiesListWatcher } from '@app/screens/module-specific-utilities/pages/metric-properties/redux';

export function* rootSaga() {
  /**
   *
   *  Register watchers
   *
   */
  const sagas: any = [
    fetchRoutesDataWatcher,
    fetchUserInfoWatcher,
    fetchAdminUsersDataWatcher,
    fetchAccessGroupMasterWatcher,
    fetchAccessGroupDropDownWatcher,
    fetchDivisionDropdownWatcher,
    fetchStateDropdownWatcher,
    editAdminUserWatcher,
    addAdminUserWatcher,
    disableAdminUserWatcher,
    fetchAdminUsersAutoSuggestDataWatcher,
    fetchAdminAddUsersAutoSuggestDataWatcher,
    fetchCannotAccessModalUsersWatcher,
    applyFilterWatcher,
    applySetDefaultFilterWatcher,
    fetchAccessGroupsAutoSuggestDataWatcher,
    editAccessGroupWatcher,
    disableAccessGroupWatcher,
    addAccessGroupWatcher,
    accessGroupMenuItemsWatcher,
    fetchPlanAndMeetStatusListWatcher,
    updateMTPStatusWatcher,
    fetchNotificationsWatcher,
    updateDirectoryNavWatcher,
    updateTabsWatcher,
    directoryNavActionHandlerWatcher,
    fetchDivisionsWatcher,
    fetchPartyGroupsWatcher,
    directoryLoadingHandlerWatcher,
    updateColumnsWatcher,
    fetchTabListWatcher,
    fetchMappingFieldsWatcher,
    fetchHoContactCategoryWatcher,
    directoryNavSearchQueryWatcher,
    fetchHoUserCategoryWatcher,
    fetchHoUserDepartmentWatcher,
    fetchHoUserDivisionWatcher,
    fetchHoUserStateWatcher,
    fetchHoUserRegionWatcher,
    fetchHoUserListWatcher,
    fetchDepartmentListWatcher,
    directoryNavToggleWatcher,
    updateSocialMediaListWatcher,
    getSocialMediaCategoryWatcher,
    fetchSocialMediaCategoryListWatcher,
    directoryUpdateCurrentPageWatcher,
    fetchPlanAndMeetStatusListWatcher,
    fetchMTPRecommendationsWatcher,
    fetchFilterOptionsWatcher,
    fetchHqsWatcher,
    filterBasedDownloadExcelWatcher,
    fetchPreferredFiltersWatcher,
    fetchMTPUnlockReasonsWatcher,
    markNotificationReadWatcher,
    enableDCRWatcher,
    fetchDcrUnlockRequestListWatcher,
    fetchDCRUnlockReasonsWatcher,
    approveDCRUnlockRequestWatcher,
    fetchUserAppSettingsWatcher,
    fetchPlanAndMeetHierarchyWatcher,
    HierarchyBasedDownloadExcelWatcher,
    fetchDCRHqsWatcher,
    dcrDownloadExcelWatcher,
    fetchPartiesBySpidWatcher,
    fetchPartyDetailBySpidWatcher,
    fetchCloningPeriodWatcher,
    transferPositionUpdateWatcher,
    fetchPositionCloningListWatcher,
    clonePositionUpdateWatcher,
    fetchApprovalWorkflowListWatcher,
    fetchMasterConfigDataWatcher,
    createApprovalResponseWatcher,
    BulkGSPUpdateWatcher,
    updateMinApproverConfigWatcher,
    modifyDCRRequestWatcher,
    incentiveNavToggleWatcher,
    incentiveNavSearchQueryWatcher,
    incentiveNavActionHandlerWatcher,
    incentiveNavStateLisWatcher,
    incentiveNavDivisionListWatcher,
    incentiveUpdateCurrentPageWatcher,
    fetchUnlockRequestWatcher,
    getUnlockRequestTypesWatcher,
    fetchYearlyQuartersWatcher,
    fetchFromHQWatcher,
    fetchToHQWatcher,
    fetchSLMWatcher,
    fetchSalesTransferWatcher,
    moduleSpecificUtilitiesLoadingHandlerWatcher,
    moduleSpecificUtilitiesNavActionHandlerWatcher,
    moduleSpecificUtilitiesNavSearchQueryWatcher,
    moduleSpecificUtilitiesNavToggleWatcher,
    moduleSpecificUtilitiesUpdateCurrentPageWatcher,
    updateModuleSpecificUtilitiesNavWatcher,
    fetchMetricCategoryListWatcher,
    fetchMetricCategoryDropdownWatcher,
    fetchPartyMastersWatcher,
    fetchMetricPropertiesListWatcher,
    modifyDCRRequestWatcher,
    webAccessUnlockWatcher,
    getWebAccessStatusWatcher,
    fetchPositionTransferListWatcher,
    validatePartyTransferWatcher,
    fetchDownloadExcelURLWatcher
  ];

  /**
   * keep everything (e.g., child tasks) alive
   *
   **/
  yield all(
    sagas.map((saga: any) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log('Error ', e);
          }
        }
      }),
    ),
  );
}
