import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put, all} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {
  approveDCRUnlockRequestCreatorTypeName,
  dcrDownloadExcelCreatorTypeName,
  dcrUnlockRequestStateActions,
  fetchDCRHQsCreatorTypeName,
  fetchDCRUnlockReasonsCreatorTypeName,
  fetchDcrUnlockRequestListCreatorTypeName,
  modifyDCRRequestCreatorTypeName,
} from './slice';
import {dcrUnlockRequestSelector} from './selector';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {
  getDcrUnlockRequestObject,
  sortDCRGridData,
} from '@app/helper/dcr-unlock-request';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {attachHQstoRegions, transformFilters} from '@app/helper/plan-and-meet';

/**
 * Employee Details Watcher
 */
export function* fetchDcrUnlockRequestListWatcher() {
  yield takeLatest(
    fetchDcrUnlockRequestListCreatorTypeName,
    fetchDcrUnlockRequestListWorker,
  );
}

/**
 * DCR Reasons Watcher
 */
export function* fetchDCRUnlockReasonsWatcher() {
  yield takeLatest(
    fetchDCRUnlockReasonsCreatorTypeName,
    fetchDCRUnlockReasonsWorker,
  );
}

/**
 * Approve DCR unlock request Watcher
 */
export function* approveDCRUnlockRequestWatcher() {
  yield takeLatest(
    approveDCRUnlockRequestCreatorTypeName,
    approveDCRUnlockRequestWorker,
  );
}

/**
 * modify DCR request Watcher
 */
export function* modifyDCRRequestWatcher() {
  yield takeLatest(modifyDCRRequestCreatorTypeName, modifyDCRRequestWorker);
}

/**
 * fetch hqs
 */
export function* fetchDCRHqsWatcher() {
  yield takeLatest(fetchDCRHQsCreatorTypeName, fetchDCRHqsWorker);
}

/**
 * dcr download excel watcher
 */
export function* dcrDownloadExcelWatcher() {
  yield takeLatest(dcrDownloadExcelCreatorTypeName, dcrDownloadExcelWorker);
}

/**
 * DCR unlock request list Worker
 */
export function* fetchDcrUnlockRequestListWorker(): any {
  try {
    const obj = yield select(
      dcrUnlockRequestSelector.getDcrUnlockRequestState(),
    );
    const request = getDcrUnlockRequestObject(obj);
    const url = API_PATH.dcrUnlockRequest.dcrUnlockRequestList;

    const response = yield call(NetworkService.post, url, request);
    if (response?.status === HTTP_OK) {
      const updatedData = sortDCRGridData(response?.data?.gridData);
      yield put(
        dcrUnlockRequestStateActions.setDcrUnlockRequestList(updatedData),
      );
      yield put(
        dcrUnlockRequestStateActions.setTotalRowsInGrid(
          response.data?.totalRecords,
        ),
      );
      const updatedFilters = transformFilters(
        response?.data?.filters,
        obj?.filters,
      );
      yield put(filterStateActions.setFilterState(updatedFilters));
    }
    yield put(appSliceActions.setAppLoading(false));
  } catch (error) {}
}

/**
 * DCR Reasons Worker
 */
export function* fetchDCRUnlockReasonsWorker(): any {
  try {
    const url = API_PATH.dcrUnlockRequest.dcrUnlockReasons;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(dcrUnlockRequestStateActions.setDCRUnlockReasons(response?.data));
  } catch (error) {
    yield put(dcrUnlockRequestStateActions.setDCRUnlockReasons([]));
  }
}

/**
 * Approve DCR unlock request Worker
 */
export function* approveDCRUnlockRequestWorker(action: any): any {
  try {
    const url = API_PATH.dcrUnlockRequest.approveDCRUnlockRequest;
    const response = yield call(NetworkService.post, url, action.payload.body);
    if (response.status === HTTP_OK) {
      yield put(
        dcrUnlockRequestStateActions.setApproveDCRResponse({
          status: response?.data,
          empName: action.payload.empName,
        }),
      );
    }
  } catch (error) {
    yield put(dcrUnlockRequestStateActions.setApproveDCRResponse(null));
  }
}

/**
 * fetch hqs
 */
export function* fetchDCRHqsWorker(action: any): any {
  try {
    const obj = yield select(
      dcrUnlockRequestSelector.getDcrUnlockRequestState(),
    );
    const payload = getDcrUnlockRequestObject(obj);

    const filters = yield select(filterSelector.getFilterState());
    const url = `${API_PATH.dcrUnlockRequest.dcrHqsByRegion}?regionId=${action.payload.regionId}`;
    const response = yield call(NetworkService.post, url, payload);
    const updatedFilters = attachHQstoRegions(
      filters,
      action.payload.regionId,
      action.payload.zoneId,
      response?.data,
      obj?.filters,
    );
    yield put(filterStateActions.setFilterState(updatedFilters));
  } catch (error) {
    yield put(filterStateActions.setFilterState({}));
  }
}

/**
 * DCR download excel worker
 */
export function* dcrDownloadExcelWorker(): any {
  try {
    const obj = yield select(
      dcrUnlockRequestSelector.getDcrUnlockRequestState(),
    );
    const payload = getDcrUnlockRequestObject(obj);
    const url = API_PATH.dcrUnlockRequest.downloadExcel;
    const response = yield call(NetworkService.post, url, payload);
    if (response.status == HTTP_OK) {
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
      const outputFilename = `dcr_${Date.now()}.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } else {
      console.log('error');
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * modify DCR worker
 */
export function* modifyDCRRequestWorker(action: any): any {
  try {
    const url = API_PATH.dcrUnlockRequest.modifyDCRRequest;
    const response = yield call(NetworkService.post, url, action.payload.body);
    if (response.status === HTTP_OK) {
      yield put(
        dcrUnlockRequestStateActions.setModifyDCRResponse({
          status: response?.data,
          empName: action.payload.empName,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}
